
@font-face {
  font-family: 'CEWEHead';
  src: url('CEWEHead-Regular.woff2') format('woff2');
}

body {
  margin: 0;
  padding: 0;
  background-color: #bebfc1;
  font-family: 'CEWEHead', sans-serif;
  -moz-font-feature-settings:    "kern" 1; 
  -ms-font-feature-settings:     "kern" 1; 
  -o-font-feature-settings:      "kern" 1; 
  -webkit-font-feature-settings: "kern" 1; 
  font-feature-settings:         "kern" 1;
  font-kerning: normal;
 }



.App {
  text-align: center;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #DEE0E1;
  padding: 10px;
}

.logo-container {
  width: 100px;
  height: 100px;
  background-color: #A4211E;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  max-width: 70%;
  max-height: 70%;
}

button {
  padding: 20px 30px;
  background-color: #ffffff;
  color: #222222;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 24px;
  outline: none; 
}

button:active {
  background-color: #eeeeee;
}

button:disabled {
  background-color: #aaaaaa;
}

progress {
  -webkit-appearance: progress-bar;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 10;
}

progress::-webkit-progress-bar {
  background-color: #c30c15;
}

progress::-webkit-progress-value {
  background-color: #0094dc;
}

progress::-moz-progress-bar {
  background-color: #0094dc;
}

.container {
  text-align: center;
  padding: 20px;
}

.no-photos-selected, .session-expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  height: 200px;
  margin-top: 50px;
  font-size: 20px;
}

p.attention {
  color: #c30c15;
}


video {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: auto;
}